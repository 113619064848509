<template>
  <div class="body">
    <div style="font-size: 2.5vw;margin-top: 3.5vw">帮助视频</div>
    <div class="brief"><span style="cursor: pointer;text-decoration: underline;" @click="startGuide(55708)">点击开始新手引导</span>，使用过程中发现有些功能还不熟悉？下面这些视频可以帮到您</div>
    <table style="margin:3vw auto">
      <tr>
        <td v-for="item in videos" :key = "item" class="video-container">
          <img class = "helpVideo" preload :src="item.preload" style="width: 25vw; height: 14vw; cursor:pointer; margin:1vw;"
                 @click = "showVideo(item.url)">
          <div class="info">{{item.name}}  <span style="color:#8e8e8e;">{{item.time}}</span></div>
        </td>
      </tr>
      <tr style="display: block; margin-top:2vw">
        <td v-for="item in moreVideos" :key = "item" class="video-container" >
          <img class = "helpVideo" preload :src="item.preload" style="width: 25vw; height: 14vw; cursor:pointer; margin:1vw;"
                 @click = "showVideo(item.url)">
          <div class="info">{{item.name}}  <span style="color:#8e8e8e;">{{item.time}}</span></div>
        </td>
      </tr>
    </table>

<!--    <div class="show-more" v-show="!showMore" @click="showMore=!showMore">查看所有帮助视频</div>-->
    <el-dialog
        v-model="tutorialVisible"
        width="65vw"
        style="padding:10px 20px"
    >
      <video id="tutorialVideo"  :src="videoUrl" controls="controls" style="width:60vw;"
      >
        您的浏览器不支持 video 标签。
      </video>
    </el-dialog>
  </div>
</template>

<script>
import {ElLoading} from "element-plus";
import {createGuide} from "../api/api";
import config from "../config";
// 本地新手引导id：6386，metago：55708
export default {
  name: "Help",
  data:function(){
    return{
      tutorialVisible:false,
      showMore:false,
      videoUrl:'',
      videos:[
        {
          url:'https://uranus-static.oss-cn-beijing.aliyuncs.com/metago/demo1.mp4',
          name:'三分钟快速上手MetaGo',
          time:'(2分51秒)',
          preload:'https://uranus-static.oss-cn-beijing.aliyuncs.com/metago/demo1.png'
        },
        {
          url:'https://uranus-static.oss-cn-beijing.aliyuncs.com/metago/demo2.mp4',
          name:'高级设置',
          time:'(3分0秒)',
          preload:'https://uranus-static.oss-cn-beijing.aliyuncs.com/metago/demo2.png'
        },
        {
          url:'https://uranus-static.oss-cn-beijing.aliyuncs.com/metago/demo3.mp4',
          name:'其它问题类型',
          time:'(4分39秒)',
          preload:'https://uranus-static.oss-cn-beijing.aliyuncs.com/metago/demo3.png'
        },
      ],

      moreVideos:[
        {
          url:'https://uranus-static.oss-cn-beijing.aliyuncs.com/metago/demo4.mp4',
          name:'问题条件与顺序',
          time:'(2分11秒)',
          preload:'https://uranus-static.oss-cn-beijing.aliyuncs.com/metago/demo4.png'
        }
      ],
      freeze:false,
    }
  },
  methods:{
    showVideo:function(url){
      this.tutorialVisible = true;
      this.videoUrl = url;
    },
    onload:function(){
      document.getElementsByClassName('helpVideo').forEach(i => i.classList.add('video-background'))
    },
    startGuide:function (sourceFileId) {
      if (this.freeze) {
        return;
      }
      this.freeze = true;
      let loadingInstance = ElLoading.service({ fullscreen: true, body: true, lock: true, spinner: 'el-icon-loading' });
      loadingInstance.setText('正在创建新手引导文档')
      createGuide(sourceFileId).then(res => {
        let newFiledId = res.data.data.fileId
        let fileName = res.data.data.fileName;
        loadingInstance.setText('创建成功，开始跳转')
        window.location.href = config.baseUrl + "/template/doc?fileId=" + newFiledId + "&fileName=" + fileName + "&guide=true";
      }).catch(error => {
        console.error(error)
        loadingInstance.setText('创建失败')
      }).finally(() => {
        this.freeze = false;
        setTimeout(() => loadingInstance.close(), 1000)
      });
    }
  }
}
</script>

<style scoped>
.brief{
  font-size: 1.5vw;
  margin-top:1.5vw;
  color:#8e8e8e;
}
.video-container{
  padding:0 0.5vw;
  margin:10vw;
}
.show-more{
  position:relative;
  margin-top:2vw;
  text-align: center;
  font-size: 1.2vw;
  color:#26bab2;
}
.show-more:hover{
  cursor:pointer;
}
.info{
  font-size: 1.3vw;
}
</style>
